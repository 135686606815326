import {FLMCohort, UtilityId} from "@/stores/flm/types";
import api from "@/api";
import {DateTime, Interval} from "luxon";
import {AccountBill, EventPerformance, LogbookEntry} from "@/api/client/site";
import {AxiosRequestConfig} from "axios";
import {camelize} from "humps";

export enum EventProcessingStatus {
  PROCESSING = "PROCESSING",
  ERROR = "ERROR",
  COMPLETE = "COMPLETE",
}

export interface DemandResponseCurtailmentLevel {
  id: number;
  name: string;
  meta?: any;
}

export interface DemandResponseAsset {
  id: number;
  name: string;
  groupId: number;
  levels: Array<DemandResponseCurtailmentLevel>;

  isDisabled: boolean;
  canRoll: boolean;
  maxDuration: number;
  defaultDuration: number;
  rollingGroup: number | null;

  minNotification: number;
  minNotificationHour: number;
  earliestAvailability: DateTime,
}

export interface DemandResponseGroup {
  id: number;
  name: string;
  color: string;
  assets: Array<DemandResponseAsset>;
}

export interface FLMEventTargetInterval {
  levelId: number | null;
  interval: Interval;
}

export interface FLMEventTarget {
  assetId: number;
  groupId: number;
  intervals: Array<FLMEventTargetInterval>;
  processingStatus?: EventProcessingStatus;
}

export interface FLMEvent {
  id: number;
  utility: UtilityId;
  isFcm: boolean;
  isTest: boolean;
  isCancelled: boolean;
  date: string;
  timing: Interval; // earliest and latest target intervals
  targets: Array<FLMEventTarget>;
}

export interface EventUpdatePayload {
  id?: number;
  targets: Array<FLMEventTarget>;
}

export interface EventPayload extends EventUpdatePayload {
  date: string;
  isFcm: boolean;
  isTest: boolean;
}

export interface AccountBillPayload {
  cycleStart: DateTime;
  cycleEnd: DateTime;
}

export interface SystemForecastResponse {
  rows: Array<any>;
  stats: {
    mean: number;
    stdev: number;
  };
  peakToDate: {
    load: number;
    dt: DateTime;
  };
}

export interface EventPreviewSystemForecastRow {
  dt: DateTime;
  load:number;
}

export interface EventPreviewCustomerForecastRow {
  dt: DateTime;
  forecast: number;
  forecastPotential: number;
}

export interface PeakResponse {
  current: {
    dt: DateTime;
    load: number;
  };
  previous: {
    dt: DateTime;
    load: number;
  };
  sub: Array<{
    x: number;
    current: number;
    previous: number;
  }>;
}

export interface AccountCredit {
  year: number;
  month: number;
  type: string;
  accountId: string;
  aliasId: string;
  site: number;
  credit: number;
  isBilled: boolean;
}

interface EventLoadsRow {
  dt: DateTime;
  actual: number;
  baseline: number;
}

export interface SystemModelAnalysisRow {
  'dt': DateTime;
  actual: number;
  forecast: number;
  forecastGross: number;
  forecastPrt: number;
  resMw: number;
  indMw: number;
  hvacMw: number;
  solarMw: number;
  temp: number;
  ghi: number;
  windSpeed: number;
  precipitation: number;
  error: number;
}

export enum PeakType {
  RNS = "RNS",
  FCM = "FCM",
}

export interface AggregateSiteSchedule {
  siteId: number;
  hour: DateTime;
  level: number;
}

export class UtilityAPIClient {
  async getBills(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    bills: Array<AccountBill>
  }> {
    const resp = await api.get<{ bills: Array<AccountBill> }>(
        `/utility/${utilityId}/bills/`,
        opts,
    );
    resp.data.bills = resp.data.bills.map((bill) => {
      // make lookup easier by camelizing the bucket, since the prices/costs/etc are camelCase
      if (bill.flmCohort === 'FLM3_FLAT')
        bill.flmBill.extra.bucket = camelize(bill.flmBill.extra.bucket);

      return bill;
    });

    return resp.data;
  }

  async createBill(utilityId: UtilityId, accountId: string, bill: AccountBillPayload, opts?: AxiosRequestConfig): Promise<{
    bill: AccountBill
  }> {
    opts = {...opts, timeout: 30000};
    const resp = await api.post(
        `/utility/${utilityId}/bills/${accountId}/`,
        bill,
        opts,
    );
    return resp.data;
  }

  async getEvent(utilityId: UtilityId, eventId: number, opts?: AxiosRequestConfig): Promise<{ event: FLMEvent }> {
    const resp = await api.get(
        `/utility/${utilityId}/events/${eventId}/`,
        opts,
    );

    return resp.data;
  }

  async getEvents(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    events: Array<FLMEvent>
  }> {
    const resp = await api.get<{ events: Array<FLMEvent> }>(
        `/utility/${utilityId}/events/`,
        opts,
    );

    return resp.data;
  }

  async createEvent(utilityId: UtilityId, event: EventPayload, opts?: AxiosRequestConfig): Promise<{
    event: FLMEvent
  }> {
    const resp = await api.post(`/utility/${utilityId}/events/`, event, opts);
    return resp.data;
  }

  async updateEvent(utility: UtilityId, eventId: number, newData: EventUpdatePayload, opts?: AxiosRequestConfig): Promise<{
    event: FLMEvent
  }> {
    const resp = await api.put(`/utility/${utility}/events/${eventId}/`, newData, opts);
    return resp.data;
  }

  async cancelEvent(utility: UtilityId, eventId: number, opts?: AxiosRequestConfig): Promise<{
    event: FLMEvent
  }> {
    const resp = await api.delete(`/utility/${utility}/events/${eventId}/`, opts);
    return resp.data;
  }

  async getSystemForecast(
      gridRegion: string,
      month: DateTime,
      opts?: AxiosRequestConfig,
  ): Promise<SystemForecastResponse> {
    opts = {
      ...opts, params: {
        year: month.year,
        month: month.month,
        gridRegion: gridRegion,
      },
    };
    const resp = await api.get(`/system-forecast/`, opts);
    return resp.data;
  }

  async getEventLoads(utilityId: UtilityId, eventId: number, opts?: AxiosRequestConfig): Promise<{
    rows: Array<EventLoadsRow>
  }> {
    const resp = await api.get(
        `/utility/${utilityId}/event/${eventId}/loads/`,
        opts,
    );
    return resp.data;
  }

  async getPerformances(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    events: Array<EventPerformance>
  }> {
    const resp = await api.get<{ events: Array<EventPerformance> }>(
        `/utility/${utilityId}/performance/`,
        opts,
    );
    return resp.data;
  }

  async getCredits(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    credits: Array<AccountCredit>
  }> {
    const resp = await api.get(
        `/utility/${utilityId}/credits/`,
        opts,
    );
    return resp.data;
  }

  async previewEventCustomerForecast(
      utilityId: UtilityId,
      date: DateTime,
      targets: FLMEventTarget[],
      opts?: AxiosRequestConfig,
  ): Promise<{
    rows: Array<EventPreviewCustomerForecastRow>
  }> {
    const resp = await api.post(
        `/utility/${utilityId}/event-preview/${date.toISODate()}/customer-forecast/`,
        {targets},
        opts,
    );
    return resp.data;
  }

  async getSystemLoadForecast(
      utilityId: UtilityId,
      date: DateTime,
      src: string,
      days = 1,
      opts?: AxiosRequestConfig,
  ): Promise<{
    rows: EventPreviewSystemForecastRow[];
  }> {
    const resp = await api.get(
        `/utility/${utilityId}/event-preview/${date.toISODate()}/system-forecast/${src}/days/${days}/`,
        opts,
    );
    return resp.data;
  }

  async getLogbookEntries(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    entries: Array<LogbookEntry>
  }> {
    const resp = await api.get(`/utility/${utilityId}/logbook/`, opts);
    return resp.data;
  }

  async getPeaks(type: PeakType, month: DateTime, opts?: AxiosRequestConfig): Promise<PeakResponse> {
    const url = type === PeakType.FCM ? `/peaks/fcm/${month.year}/` : `/peaks/rns/${month.year}/${month.month}/`;
    const resp = await api.get(url, opts);
    return resp.data;
  }

  async getDemandResponseGroups(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    groups: Array<DemandResponseGroup>,
    assetEventCountsMtd: Map<number, number>,
  }> {
    const resp = await api.get<{
      groups: Array<DemandResponseGroup>,
      eventCounts: Record<string, number>,
    }>(`/utility/${utilityId}/groups/`, opts);
    return {
      groups: resp.data.groups,
      assetEventCountsMtd: new Map<number, number>(
          Object.entries(resp.data.eventCounts).map(([k, v]) => [Number.parseInt(k), v]),
      ),
    };
  }

  async getSystemModelAnalysis(
      start: DateTime,
      end: DateTime,
      gridRegion: string,
      isML: boolean,
      opts?: AxiosRequestConfig,
  ): Promise<Array<SystemModelAnalysisRow>> {
    const url = isML ? `/model-analysis/system/ml/` : `/model-analysis/system/`;
    const resp = await api.get(url, {
      ...opts,
      params: {
        start: start.toISODate(),
        end: end.toISODate(),
        gridRegion: gridRegion,
      },
    });
    return resp.data;
  }

  async getSkiZoneSchedules(utilityId: UtilityId, opts?: AxiosRequestConfig): Promise<{
    schedule: Array<AggregateSiteSchedule>;
    info: Array<{
      siteId: number;
      name: string;
      drAssetId: number;
      updatedAt: DateTime | null;
    }>;
  }> {
    const resp = await api.get(`/utility/${utilityId}/ski-zone-schedules/`, opts);
    return resp.data;
  }

  async getCohortForecast(
      utilityId: UtilityId,
      cohort: FLMCohort,
      start: DateTime,
      end: DateTime,
      opts?: AxiosRequestConfig,
  ): Promise<{
    rows: Array<{
      dt: DateTime;
      forecast: number;
    }>;
  }> {
    const resp = await api.get(`/utility/${utilityId}/cohort/${cohort}/forecast/`, {
      ...opts,
      params: {
        startDt: start,
        endDt: end,
      },
    });
    return resp.data;
  }
}
