import {
  PlotStyle,
  SavedTelemetryGroup,
  TelemetryGroupOptions,
  TelemetryPoint,
  TelemetryReading,
} from "@/components/telemetry/types";
import {Account, Meter, SubmeterField} from "@/stores/flm/types";
import {AxiosRequestConfig} from "axios";
import api from "@/api";
import {DateTime} from "luxon";
import {
  TelemetryAccountItem,
  TelemetryEventItem,
  TelemetryItem,
  TelemetryMeterItem,
  TelemetryPointItem,
  TelemetrySignalItem, TelemetrySiteDemandItem,
  TelemetrySubmeterFieldItem,
  TelemetryWeatherItem,
} from "@/components/telemetry/item";

export interface TelemetryGroupPayload extends TelemetryGroupOptions {
  id?: number;
  includeSignal: boolean;
  includeEvents: boolean;
  includeAggregateDemand: boolean;
}

type ItemPayload = { plotStyle: PlotStyle; order: number };

export interface TelemetryGroupRequestPayload extends TelemetryGroupPayload {
  pointItems: Array<{ pointId: string } & ItemPayload>;
  accountItems: Array<{ accountId: string } & ItemPayload>;
  meterItems: Array<{ meterId: string } & ItemPayload>;
  weatherItems: Array<{ field: string } & ItemPayload>;
  submeterFieldItems: Array<{ submeterFieldId: number } & ItemPayload>;
}

export type TelemetryGroupCreateRequestPayload =
    Pick<TelemetryGroupRequestPayload, 'name'>
    & Partial<Omit<TelemetryGroupRequestPayload, 'id' | 'name'>>;

export interface TelemetryGroupResponsePayload extends TelemetryGroupPayload {
  id: number;
  pointItems: Array<{ point: TelemetryPoint } & ItemPayload>;
  accountItems: Array<{ account: Account } & ItemPayload>;
  meterItems: Array<{ meter: Meter } & ItemPayload>;
  weatherItems: Array<{ field: string } & ItemPayload>;
  submeterFieldItems: Array<{ submeterField: SubmeterField } & ItemPayload>;
}

export interface TelemetryLayoutRequestPayload {
  name: string;
  items: Array<{ order: number, groupId: number }>;
}

export interface TelemetryLayoutResponsePayload extends TelemetryLayoutRequestPayload {
  id: number;
}

export interface WeatherObservationRow {
  dt: DateTime;
  temperature: number;
  apparentTemperature: number;
  windChillTemperature: number;
  heatIndex: number;
  wetBulbTemperature: number;
  dewPointTemperature: number;
  humidity: number;
  cloudCover: number;
  mslPressure: number;
  windSpeed: number;
  windGust: number;
  windDirection: number;
  precipitation: number;
  snowfall: number;
  globalHorizontalIrradiance: number;
  directNormalIrradiance: number;
}

export class TelemetryAPIClient {
  async getPoints(siteId: number, opts?: AxiosRequestConfig): Promise<{
    points: Array<TelemetryPoint>
  }> {
    const resp = await api.get(
        `/site/${siteId}/telemetry/points/`,
        opts,
    );
    return resp.data;
  }

  async getLayouts(siteId: number, opts?: AxiosRequestConfig): Promise<{
    layouts: Array<TelemetryLayoutResponsePayload>
  }> {
    const resp = await api.get(
        `/site/${siteId}/telemetry/layouts/`,
        opts,
    );
    return resp.data;
  }

  async updateLayout(
      siteId: number,
      layoutId: number,
      data: Partial<TelemetryLayoutRequestPayload>,
      opts?: AxiosRequestConfig,
  ): Promise<{ layout: TelemetryLayoutResponsePayload }> {
    const resp = await api.patch(
        `/site/${siteId}/telemetry/layout/${layoutId}/`,
        data,
        opts,
    );
    return resp.data;
  }

  async getGroups(siteId: number, opts?: AxiosRequestConfig): Promise<{
    groups: Array<SavedTelemetryGroup>
  }> {
    const resp = await api.get(
        `/site/${siteId}/telemetry/groups/`,
        opts,
    );
    const groups = resp.data.groups.map((group: TelemetryGroupResponsePayload) => this._initGroup(group)) ?? [];

    return {groups};
  }

  async updateGroup(siteId: number, groupId: number, data: Partial<TelemetryGroupRequestPayload>): Promise<{
    group: SavedTelemetryGroup
  }> {
    const resp = await api.patch(
        `/site/${siteId}/telemetry/group/${groupId}/`,
        data,
    );
    const group = this._initGroup(resp.data.group);
    return {group};
  }

  async createGroup(siteId: number, data: TelemetryGroupCreateRequestPayload): Promise<{
    group: SavedTelemetryGroup
  }> {
    const resp = await api.post(
        `/site/${siteId}/telemetry/groups/`,
        data,
    );
    const group = this._initGroup(resp.data.group);
    return {group};
  }

  async deleteGroup(siteId: number, groupId: number, opts?: AxiosRequestConfig) {
    await api.delete(`/site/${siteId}/telemetry/group/${groupId}/`, opts);
  }

  async getTelemetryReadings(
      siteId: number,
      pointId: string,
      start: DateTime,
      end: DateTime,
      freq?: string,
      opts?: AxiosRequestConfig,
  ): Promise<Array<TelemetryReading>> {
    let url = `/site/${siteId}/telemetry/point/${pointId}/readings/`;
    if (freq != null) {
      url += `${freq}/`;
    }
    const resp = await api.get(url, {
      ...opts,
      params: {
        startDt: start,
        endDt: end,
      },
    });
    return resp.data;
  }

  private _initGroup(group: TelemetryGroupResponsePayload): SavedTelemetryGroup {
    const items: TelemetryItem[] = group.pointItems.map((item) => {
      return new TelemetryPointItem(item.point, {plotStyle: item.plotStyle}, item.order);
    });
    group.weatherItems.forEach((item) => {
      const field = TelemetryWeatherItem.FIELDS.find((f) => f.value === item.field);
      if (field)
        items.push(new TelemetryWeatherItem(field, {plotStyle: item.plotStyle}, item.order));
    });
    group.submeterFieldItems.forEach((item) => {
      items.push(new TelemetrySubmeterFieldItem(item.submeterField, {plotStyle: item.plotStyle}, item.order));
    });
    group.accountItems.forEach((item) => {
      items.push(new TelemetryAccountItem(item.account, {plotStyle: item.plotStyle}, item.order));
    });
    group.meterItems.forEach((item) => {
      items.push(new TelemetryMeterItem(item.meter, {plotStyle: item.plotStyle}, item.order));
    });
    if (group.includeEvents)
      items.push(new TelemetryEventItem());
    if (group.includeSignal)
      items.push(new TelemetrySignalItem());
    if (group.includeAggregateDemand)
      items.push(new TelemetrySiteDemandItem());
    return {
      ...group,
      items: items.sort((a, b) => a.order - b.order),
    };
  }

  async getWeatherObservations(stationId: string, start: DateTime, end: DateTime, opts?: AxiosRequestConfig): Promise<{
    rows: WeatherObservationRow[];
  }> {
    const resp = await api.get(`/station/${stationId}/observations/`, {
      ...opts,
      params: {
        startDt: start,
        endDt: end,
      },
    });
    return resp.data;
  }

  async getWeatherForecast(cityId: number, opts?: AxiosRequestConfig): Promise<{
    rows: WeatherObservationRow[];
  }> {
    const resp = await api.get(`/city/${cityId}/forecast/`, opts);
    return resp.data;
  }

  async getStationWeatherForecast(stationId: string, opts?: AxiosRequestConfig): Promise<{
    rows: WeatherObservationRow[];
  }> {
    const resp = await api.get(`/station/${stationId}/forecast/`, opts);
    return resp.data;
  }
}
