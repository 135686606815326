import {DateTime} from "luxon";
import {TelemetryItem} from "@/components/telemetry/item";

export type Row = [number, number | undefined | null] | [number, number | undefined | null, number | undefined | null];

export type NormalPlotType = 'line' | 'spline' | 'arearange' | 'column';
export type PlotStyle = NormalPlotType | 'step' | 'arearangestep' | 'regions' | 'xrange';

export interface ItemStyle {
    plotStyle: PlotStyle;
    colorIndex: number | undefined;
    className: string | undefined;
    yAxisId: string | undefined;
    isZeroBased: boolean | undefined;
}

export type ValuePeriod<TIndex = number> = {
  start: TIndex;
  end: TIndex;
  value: number | undefined | null;
  label?: string;
};

export enum DataType {
  NUMBER = 'NUMBER',
  BOOLEAN = 'BOOLEAN',
  ENUM = 'ENUM'
}

export enum SourceType {
  ROWS,
  PERIODS,
  ROWRANGES,
}

export interface TelemetryPointMeta {
  dataType?: DataType;
  valuesText?: Record<string, string>;
  [key: string]: any;
}

export interface TelemetryPoint {
  id: string;
  siteId: number;
  isIncludedDashboard: boolean;
  path: string;
  name: string | null;
  displayName: string | null;
  unit: string | null;
  freq: number | null;
  dataType: DataType;
  interpolationMethod: 'LOCF' | 'LINEAR';
  meta: TelemetryPointMeta;
}

export interface TelemetryReading {
  dt: DateTime;
  value?: number | null;
}

export interface TelemetryReadingRange {
    dt: DateTime;
    value?: [number | undefined | null, number | undefined | null];
}

export interface TelemetryGroupOptions {
  name: string;
  usePanes: boolean;
  showNavigator: boolean;
  height: number | null;
  freq: number;

  allowGrouping?: boolean;
  showRangeSelector?: boolean;
}

export interface TelemetryGroup extends TelemetryGroupOptions {
  id?: number;
  items: Array<TelemetryItem>;
}

export interface SavedTelemetryGroup extends TelemetryGroup {
  id: number;
}

export interface TelemetryLayoutItem {
  order: number | null;
  group: SavedTelemetryGroup;
}

export interface SavedTelemetryLayout {
  id: number;
  name: string;
  items: TelemetryLayoutItem[];
}
